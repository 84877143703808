import React, {useEffect} from "react";
import ReactFlagsSelect from "react-flags-select";
import withStyles from "react-jss";

//import css module
//import "react-flags-select/css/react-flags-select.css";
import { useTranslation } from "react-i18next";

const styles = {
  flags: {
    marginTop: 10,
  },
};
const Nav = ({ classes }) => {
  const { t, i18n } = useTranslation();

  useEffect(()=>{
    i18n.changeLanguage('en')
  },[i18n])

  function handleCountrySelected(country) {
    const languages = { US: "en", BA: "bs", HR: "hr", RS: "sr" };
    i18n.changeLanguage(languages[country]);
  }
  function getDefaultCountryByLanguage(lang) {
    const countries = { en: "US", bs: "BA", sr: "RS", hr: "HR" };
    return countries[lang];
  }

  return (
    <nav
      role="navigation"
      className={"navbar navbar-custom navbar-fixed-top top-nav-collapse"}
    >
      <div className={"container"}>
        <div className={"navbar-header page-scroll"}>
          <button
            type="button"
            data-toggle="collapse"
            data-target=".navbar-main-collapse"
            className="navbar-toggle"
          >
            <i className={"fa fa-bars"} />
          </button>
          <a href={"#intro"} className={"navbar-brand"}>
            <img src={"img/SpicaRising64.png"} alt={"Intro"} />
          </a>
        </div>
        <div
          className={
            "collapse navbar-collapse navbar-right navbar-main-collapse"
          }
        >
          <ul className={"nav navbar-nav"}>
            <li className={"active"}>
              <a href={"#intro"}>{t("Home")}</a>
            </li>
            <li>
              <a href={"#about"}>{t("About Us")}</a>
            </li>
            <li role="presentation" className={"dropdown"}>
              <button
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className={"dropdown-toggle"}
              >
                <span>{t("Health Insurance")}</span>
                <span className={"caret"} />
              </button>
              <ul className={"dropdown-menu"}>
                <li>
                  <a href={"#health-insurance"}>
                    {t("Individual Major Medical")}
                  </a>
                </li>
                <li>
                  <a href={"#health-insurance"}>{t("Limited Medical Plans")}</a>
                </li>
                <li>
                  <a
                    href={
                      "https://www.hpainsurance.com/qt/SecureSTM/default.aspx?agent=T0023920000"
                    }
                    target="_quote"
                  >
                    {t("Individual Short-Term Medical")}
                  </a>
                </li>
                <li>
                  <a href={"#health-insurance"}>
                    {t("Group Individual Major Medical")}
                  </a>
                </li>
              </ul>
            </li>
            <li role="presentation" className="dropdown">
              <button
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className={"dropdown-toggle"}
              >
                {t("Life Insurance")}
                <span className={"caret"} />
              </button>
              <ul className={"dropdown-menu"}>
                <li>
                  <a href={"#life-insurance"}>{t("Term Life")}</a>
                </li>
                <li>
                  <a href={"#life-insurance"}>{t("Whole Life")}</a>
                </li>
                <li>
                  <a href={"#life-insurance"}>{t("Universal Life")}</a>
                </li>
              </ul>
            </li>

            <li>
              <a href={"#contact"}>{t("Contact Us")}</a>
            </li>
            <li>
              <ReactFlagsSelect
                className={classes.flags}
                defaultCountry={getDefaultCountryByLanguage(i18n.language)}
                countries={["US", "BA", "HR", "RS"]}
                customLabels={{
                  US: t("United States"),
                  BA: t("Bosnia and Herzegovina"),
                  HR: t("Croatia"),
                  RS: t("Serbia"),
                }}
                placeholder="Select Language"
                showSelectedLabel={false}
                selectedSize={14}
                optionsSize={14}
                onSelect={handleCountrySelected}
              />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default withStyles(styles)(Nav);
