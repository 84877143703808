import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

const EmailUsForm = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className={"col-md-6"}>
        <div className={"form-group"}>
          <label htmlFor="name">{t("Name")}</label>
          <input
            id="name"
            type="text"
            placeholder={t("Enter name")}
            required="required"
            className={"form-control"}
          />
        </div>
        <div className={"form-group"}>
          <label htmlFor="email">{t("Email Address")}</label>
          <div className={"input-group"}>
            <span className="input-group-addon">
              <span className={"glyphicon glyphicon-envelope"} />
            </span>
            <input
              id="email"
              type="email"
              placeholder={t("Enter email")}
              required="required"
              className="form-control"
            />
          </div>
        </div>
        <div className={"form-group"}>
          <label htmlFor="subject">{t("Subject")}</label>
          <select
            id="subject"
            defaultValue={"na"}
            name="subject"
            required="required"
            className="form-control"
          >
            <option value="na">{t("Choose One")}:</option>
            <option value="service">{t("General Customer Service")}</option>
            <option value="suggestions">{t("Suggestions")}</option>
            <option value="product">{t("Product Support")}</option>
          </select>
        </div>
      </div>
    </Fragment>
  );
};

const ContactInfo = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className={"col-md-6"}>
        <div className={"form-group"}>
          <label htmlFor="name">{t("Message")}</label>
          <textarea
            id="message"
            name="message"
            rows="9"
            cols="25"
            required="required"
            placeholder="Message"
            className="form-control"
          />
        </div>
      </div>
      <div className={"col-md-12"}>
        <button
          id="btnContactUs"
          type="submit"
          className="btn btn-skin pull-right"
        >
          {t("Send Message")}
        </button>
      </div>
    </Fragment>
  );
};

const EmailUsFormContainer = () => {
  return (
    <div className={"col-lg-8"}>
      <div className={"boxed-grey"}>
        <form id="contact-form">
          <div className={"row"}>
            <EmailUsForm />

            <ContactInfo />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailUsFormContainer;
