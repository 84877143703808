import React from "react";

const RetirementPlanningPartners = () => {
  return (
    <section
      id="retirement-planning"
      className="home-section text-center bg-gray"
    />
  );
};

export default RetirementPlanningPartners;
