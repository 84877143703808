import React from "react";
import "./App.css";
import Nav from "./components/Nav";
import Intro from "./components/IntroSection";
import About from "./components/About";
import HealthInsurancePartners from "./components/HealthInsurancePartners";
import LifeInsurancePartners from "./components/LifeInsurancePartners";
import RetirementPlanningPartners from "./components/RetirementPlanningPartners";
import ContactUsSection from "./components/ContactUsSection";
import { initialState } from "./reducers/healthInsurancePartners";

export const AppContext = React.createContext({ partners: initialState });

const App = ()=> {
  return (
    <AppContext.Provider>
      <Nav />
      <Intro />
      <About />
      <HealthInsurancePartners />
      <LifeInsurancePartners />
      <RetirementPlanningPartners />
      <ContactUsSection />

      <footer>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-md-12 col-lg-12"}>
              <div data-wow-delay="0.4s" className={"wow shake"}>
                <div className={"page-scroll marginbot-30"}>
                  <a id="totop" href="#intro" className="btn btn-circle">
                    <i className="fa fa-angle-double-up animated" />
                  </a>
                </div>
              </div>
              <p>©Copyright 2015 Spica Insurance</p>
            </div>
          </div>
        </div>
      </footer>
    </AppContext.Provider>
  );
};

export default App;
