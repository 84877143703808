const officePhoneLink = "tel:8478735385";
export const initialState = [
  {
    id: 1,
    items: [
      {
        id: "aetna",
        logoImage: "/img/partners/aetna.png",
        quoteLink: officePhoneLink,
      },
      { id: "anthem", name: "Anthem BC", quoteLink: officePhoneLink },
      {
        id: "bcbs",
        logoImage: "/img/partners/bcbs.png",
        quoteLink:
          "https://retailweb.hcsc.net/retailshoppingcart/IL/census?ExpressLinkedAgentId=256249&amp;previewMode=1",
      },
    ],
  },
  {
    id: 2,
    items: [
      {
        id: "humana",
        logoImage: "/img/partners/Humana.png",
        quoteLink: officePhoneLink,
      },
      {
        id: "lincoln",
        name: "Land Of Lincoln",
        quoteLink:
          "https://secure.bswift.com/default.aspx?abbrev=llhindividual&amp;bc=7840849",
      },
      {
        id: "ml",
        name: "Manhattan Life",
        quoteLink: "http://www.manhattanlife.com/agency/DajanaDikic52TH167",
      },
    ],
  },
  {
    id: 3,
    items: [
      { id: "ssl", name: "Standard Security Life", quoteLink: officePhoneLink },
      { id: "uho", name: "United Health One", quoteLink: officePhoneLink },
    ],
  },
];
