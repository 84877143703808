import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      Home: "Home",
      "About Us": "About Us",
      "Health Insurance": "Health Insurance",
      "Individual Major Medical": "Individual Major Medical",
      "Limited Medical Plans": "Limited Medical Plans",
      "Individual Short-Term Medical": "Individual Short-Term Medical",
      "Group Individual Major Medical": "Group Individual Major Medical",

      "Life Insurance": "Life Insurance",
      "Term Life": "Term Life",
      "Whole Life": "Whole Life",
      "Universal Life": "Universal Life",
      "Contact Us": "Contact Us",

      "United States": "United States",
      "Bosnia and Herzegovina": "Bosnia and Herzegovina",
      Croatia: "Croatia",
      Serbia: "Serbia",

      "WELCOME TO": "WELCOME TO",
      Insurance: "Insurance",
      "Don't miss Open Enrollment": "Don't miss Open Enrollment",
      "November 1st, 2019 until December 15th, 2019":
        "November 1st, 2019 until December 15th, 2019",
      "Click Here": "Click Here",
      or: "or",
      call: "call",

      "Our Mission": "Our Mission Is Your Peace of Mind",
      "Our Mission p1":
        "Spica Rising is an insurance brokerage agency with over sixteen years of experience, connecting\n" +
        "                individuals, families and groups to a network of superior insurance companies for health, life and\n" +
        "                personal retirement.",
      "Our Mission p2":
        "What makes Spica unique is our genuine approach to customer service and simplifying the insurance process.\n" +
        "                From understanding your coverage needs to finding the right plan and helping you in the application process,\n" +
        "                we are with you every step of the way. We are passionate in making the insurance process a smooth and\n" +
        "                stress-free experience and most\n" +
        "                importantly provide you with superior, quality coverage at an affordable cost.",

      "How it works?": "How it works?",
      "The 3 C's": "The 3 C’s",
      Call: "Call",
      "All it takes is one phone call...":
        "All it takes is one phone call. We will evaluate your needs and get working to put together a comprehensive and complete package.",
      Compare: "Compare",
      "We present several package options...":
        "We present several package options along with quotes to give you the opportunity to compare and make the best decision for you.",
      Complete: "Complete",
      "Once you decide...":
        "Once you decide on the right insurance package, we will work with you to complete the application process and give you the peace of mind that you’re well insured for the future.",
      "I couldnt have asked ...":
        "\"I couldn't have asked for a better experience. Not only did they help me pick out my life insurance plan, they also completed my application for me. &nbsp; What normally was an intimidating and painful process was simple and stress-free.”",

      "Get in touch": "Get in touch",

      Name: "Name",
      "Enter name": "Enter name",
      "Email Address": "Email Address",
      "Enter email": "Enter email",
      Subject: "Subject",
      "Choose One": "Choose One",
      "General Customer Service": "General Customer Service",
      Suggestions: "Suggestions",
      "Product Support": "Product Support",
      Message: "Message",
      "Send Message": "Send Message",

      Partners: "Partners",
      "Call Us": "Call Us",
      "Get a Quote": "Get a Quote",
      "We are on social networks": "We are on social networks",
    },
  },
  bs: {
    translation: {
      Home: "Početna",
      "About Us": "O Nama",
      "Health Insurance": "Zdravstveno Osiguranje",
      "Individual Major Medical": "Osobno Glavno Medicinsko",
      "Limited Medical Plans": "Ograničeni Medicinski Planovi",
      "Individual Short-Term Medical": "Osobno Kratkoročno Medicinsko",
      "Group Individual Major Medical": "Grupni Osobni Glavni Medicinski",

      "Life Insurance": "Životno Osiguranje",
      "Term Life": "Kratkoročno Životno ",
      "Whole Life": "Dugoročno Životno",
      "Universal Life": "Univerzalno Životno",
      "Contact Us": "Kontaktirajte nas",

      "United States": "Sjedinjene Države",
      "Bosnia and Herzegovina": "Bosna i Hercegovina",
      Croatia: "Hrvatska",
      Serbia: "Srbija",

      "WELCOME TO": "DOBRO DOŠLI DO",
      Insurance: "Osiguranja",
      "Don't miss Open Enrollment": "Ne propustite otvoreni upis",
      "November 1st, 2019 until December 15th, 2019":
        "1-og Novembra 2019 do 15-tog Decembra 2019",
      "Click Here": "Kliknite ovdje",
      or: "ili",
      call: "nazovite",

      "Our Mission": "Naša misija je vaš mir",
      "Our Mission p1":
        "Spica Rising je agencija za posredovanje u osiguranju sa preko šesnaest godina iskustva, povezujući pojedince, obitelji i grupe u mrežu vrhunskih osiguravajućih društava za zdravlje, život i osobno umirovljenje.",
      "Our Mission p2":
        "Ono što Spicu čini jedinstvenom je naš istinski pristup korisničkoj službi i pojednostavljenje procesa osiguranja. Od razumijevanja vaših potreba pokrivenosti do pronalaženja pravog plana i pomoći u procesu prijave, mi smo sa vama na svakom koraku. Mi smo privrženi u tome da proces osiguranja postane jednostavan i iskustvo bez stresa i najvažnije da vam pružimo vrhunsku, kvalitetnu pokrivenost po pristupačnoj cijeni.",

      "How it works?": "I kako započeti?",
      "The 3 C's": "",
      Call: "Nazovite",
      "All it takes is one phone call...":
        "Potreban je samo jedan telefonski poziv. Mi ćemo procijeniti vaše potrebe i raditi na tome da sastavimo sveobuhvatan i kompletan paket.",
      Compare: "Uporedite",
      "We present several package options...":
        "Predstavljamo nekoliko opcija paketa zajedno sa citatima da vam pružimo priliku da uporedite i napravite najbolju odluku za vas.",
      Complete: "Završite",
      "Once you decide...":
        "Kada se odlučite za pravi paket osiguranja, mi ćemo raditi sa vama da završimo proces prijave i pružimo vam mir da ste dobro osigurani za budućnost.",
      "I couldnt have asked ...":
        '"Nisam mogla tražiti bolje iskustvo. Ne samo da su mi pomogli da odaberem svoj plan životnog osiguranja, već su oni i završili moju prijavu za mene. & nbsp; Ono što je obično bilo zastrašujući i bolan proces bilo je jednostavno i bez stresa.”',

      "Get in touch": "Kontaktirajte nas",

      Name: "Ime",
      "Enter name": "Unesite ime",
      "Email Address": "Email Addresa",
      "Enter email": "Unesite email",
      Subject: "Natpis",
      "Choose One": "Odaberite jednu",
      "General Customer Service": "Opće Usluge",
      Suggestions: "Preporuke",
      "Product Support": "Pomoć",
      Message: "Poruka",
      "Send Message": "Pošaljite Poruku",

      Partners: "Partneri",
      "Call Us": "Nazovite Nas",
      "Get a Quote": "Zatražite Citat",
      "We are on social networks": "Mi smo na socijalnim mrežama",
    },
  },
  hr: {
    translation: {
      Home: "Početna",
      "About Us": "O Nama",
      "Health Insurance": "Zdravstveno Osiguranje",
      "Individual Major Medical": "Osobno Glavno Medicinsko",
      "Limited Medical Plans": "Ograničeni Medicinski Planovi",
      "Individual Short-Term Medical": "Osobno Kratkoročno Medicinsko",
      "Group Individual Major Medical": "Grupni Osobni Glavni Medicinski",

      "Life Insurance": "Životno Osiguranje",
      "Term Life": "Kratkoročno Životno ",
      "Whole Life": "Dugoročno Životno",
      "Universal Life": "Univerzalno Životno",
      "Contact Us": "Kontaktirajte nas",

      "United States": "Sjedinjene Države",
      "Bosnia and Herzegovina": "Bosna i Hercegovina",
      Croatia: "Hrvatska",
      Serbia: "Srbija",

      "WELCOME TO": "DOBRO DOŠLI DO",
      Insurance: "Osiguranja",
      "Don't miss Open Enrollment": "Ne propustite otvoreni upis",
      "November 1st, 2019 until December 15th, 2019":
        "1-og Novembra 2019 do 15-tog Decembra 2019",
      "Click Here": "Kliknite ovdje",
      or: "ili",
      call: "nazovite",

      "Our Mission": "Naša misija je vaš mir",
      "Our Mission p1":
        "Spica Rising je agencija za posredovanje u osiguranju sa preko šesnaest godina iskustva, povezujući pojedince, obitelji i grupe u mrežu vrhunskih osiguravajućih društava za zdravlje, život i osobno umirovljenje.",
      "Our Mission p2":
        "Ono što Spicu čini jedinstvenom je naš istinski pristup korisničkoj službi i pojednostavljenje procesa osiguranja. Od razumijevanja vaših potreba pokrivenosti do pronalaženja pravog plana i pomoći u procesu prijave, mi smo sa vama na svakom koraku. Mi smo privrženi u tome da proces osiguranja postane jednostavan i iskustvo bez stresa i najvažnije da vam pružimo vrhunsku, kvalitetnu pokrivenost po pristupačnoj cijeni.",

      "How it works?": "I kako započeti?",
      "The 3 C's": "",
      Call: "Nazovite",
      "All it takes is one phone call...":
        "Potreban je samo jedan telefonski poziv. Mi ćemo procijeniti vaše potrebe i raditi na tome da sastavimo sveobuhvatan i kompletan paket.",
      Compare: "Uporedite",
      "We present several package options...":
        "Predstavljamo nekoliko opcija paketa zajedno sa citatima da vam pružimo priliku da uporedite i napravite najbolju odluku za vas.",
      Complete: "Završite",
      "Once you decide...":
        "Kada se odlučite za pravi paket osiguranja, mi ćemo raditi sa vama da završimo proces prijave i pružimo vam mir da ste dobro osigurani za budućnost.",
      "I couldnt have asked ...":
        '"Nisam mogla tražiti bolje iskustvo. Ne samo da su mi pomogli da odaberem svoj plan životnog osiguranja, već su oni i završili moju prijavu za mene. & nbsp; Ono što je obično bilo zastrašujući i bolan proces bilo je jednostavno i bez stresa.”',

      "Get in touch": "Kontaktirajte nas",

      Name: "Ime",
      "Enter name": "Unesite ime",
      "Email Address": "Email Addresa",
      "Enter email": "Unesite email",
      Subject: "Natpis",
      "Choose One": "Odaberite jednu",
      "General Customer Service": "Opće Usluge",
      Suggestions: "Preporuke",
      "Product Support": "Pomoć",
      Message: "Poruka",
      "Send Message": "Pošaljite Poruku",

      Partners: "Partneri",
      "Call Us": "Nazovite Nas",
      "Get a Quote": "Zatražite Citat",
      "We are on social networks": "Mi smo na socijalnim mrežama",
    },
  },
  sr: {
    translation: {
      Home: "Почетна",
      "About Us": "О Нама",
      "Health Insurance": "Здравствено Осигурање",
      "Individual Major Medical": "Особно Главно Медицинско",
      "Limited Medical Plans": "Ограничени Медицински Планови",
      "Individual Short-Term Medical": "Особно Краткорочно Медицинско",
      "Group Individual Major Medical": "Групни Особни Главни Медицински",

      "Life Insurance": "Животно Осигурање",
      "Term Life": "Краткорочно Животно",
      "Whole Life": "Дугорочно Животно",
      "Universal Life": "Универзално Животно",
      "Contact Us": "Контактирајте нас",

      "United States": "Сједињене Државе",
      "Bosnia and Herzegovina": "Босна и Херцеговина",
      Croatia: "Хрватска",
      Serbia: "Србија",

      "WELCOME TO": "ДОБРО ДОШЛИ ДО",
      Insurance: "Осигурања",
      "Don't miss Open Enrollment": "Не пропустите отворени упис",
      "November 1st, 2019 until December 15th, 2019":
        "1-og Novembra 2019 do 15-tog Decembra 2019",
      "Click Here": "Кликните овде",
      or: "или",
      call: "назовите",

      "Our Mission": "Наша мисија је ваш мир",
      "Our Mission p1":
        "Спица Рисинг је агенција за посредовање у осигурању са преко шеснаест година искуства, повезујући појединце, обитељи и групе у мрежу врхунских осигуравајућих друштава за здравље, живот и особно умировљење.",
      "Our Mission p2":
        "Оно што Спицу чини јединственом је наш истински приступ корисничкој служби и поједностављење процеса осигурања. Од разумевања ваших потреба покривености до проналажења правог плана и помоћи у процесу пријаве, ми смо са вама на сваком кораку. Ми смо привржени у томе да процес осигурања постане једноставан и искуство без стреса и најважније да вам пружимо врхунску, квалитетну покривеност по приступачној цени.",

      "How it works?": "И како започети?",
      "The 3 C's": "Једноставно НУЗ",
      Call: "Назовите",
      "All it takes is one phone call...":
        "Потребан је само један телефонски позив. Ми ћемо проценити ваше потребе и радити на томе да саставимо свеобухватан и комплетан пакет.",
      Compare: "Упоредите",
      "We present several package options...":
        "Представљамо неколико опција пакета заједно са цитатима да вам пружимо прилику да упоредите и направите најбољу одлуку за вас.",
      Complete: "Завршите",
      "Once you decide...":
        "Када се одлучите за прави пакет осигурања, ми ћемо радити са вама да завршимо процес пријаве и пружимо вам мир да сте добро осигурани за будућност.",
      "I couldnt have asked ...":
        '"Нисам могла тражити боље искуство. Не само да су ми помогли да одаберем свој план животног осигурања, већ су они и завршили моју пријаву за мене.   Оно што је обично било застрашујући и болан процес било је једноставно и без стреса.”',

      "Get in touch": "Контактирајте нас",

      Name: "Име",
      "Enter name": "Унесите име",
      "Email Address": "Емаил Аддреса",
      "Enter email": "Унесите емаил",
      Subject: "Натпис",
      "Choose One": "Одаберите једну",
      "General Customer Service": "Опће Услуге",
      Suggestions: "Препоруке",
      "Product Support": "Помоћ",
      Message: "Порука",
      "Send Message": "Пошаљите Поруку",

      Partners: "Партнери",
      "Call Us": "Назовите Нас",
      "Get a Quote": "Затражите Цитат",
      "We are on social networks": "Ми смо на социјалним мрежама",
    },
  },
};


i18n
// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
// learn more: https://github.com/i18next/i18next-http-backend
// want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
.use(Backend)
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
.use(LanguageDetector)
// pass the i18n instance to react-i18next.
.use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
.init({
  debug: true,
  resources,
  lng: "en",
  fallbackLng: "en",
  whitelist: ["en", "bs", "hr", "sr"],

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  }
});


export default i18n;