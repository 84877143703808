import React from "react";
import EmailUsForm from "./EmailUsForm";
import SocialLinks from "./SocialLinks";
import { useTranslation } from "react-i18next";

const ContactUsSection = () => {
  const { t } = useTranslation();

  return (
    <section id="contact" className={"home-section text-center"}>
      <div className={"heading-contact"}>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-lg-8 col-lg-offset-2"}>
              <div data-wow-delay="0.4s" className={"wow bounceInDown"}>
                <div className={"section-heading"}>
                  <h2>{t("Get in touch")}</h2>
                  <i className="fa fa-2x fa-angle-down" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-lg-2 col-lg-offset-5"}>
            <hr className="marginbot-50" />
          </div>
        </div>
        <div className={"row"}>
          <EmailUsForm />
          <SocialLinks />
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;
