import React from "react";
import { useTranslation } from "react-i18next";

const SocialLinks = () => {
  const { t } = useTranslation();

  return (
    <div className={"col-lg-4"}>
      <div className={"widget-contact"}>
        <h5>Phone: 847-873-5385</h5>
        <address>
          <strong>Email</strong>
          <br />
          <a href="mailto:#">info@spicainsurance.com</a>
        </address>
        <address>
          <strong>{t("We are on social networks")}</strong>
          <br />
          <ul>
            <li className="social-facebook">
              <a
                href="https://www.facebook.com/spicainsurance/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook" />
              </a>
            </li>
          </ul>
        </address>
      </div>
    </div>
  );
};

export default SocialLinks;
