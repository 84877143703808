import React from "react";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <section id="about" className="home-section text-center">
      <h3>{t("Our Mission")}</h3>
      <p>{t("Our Mission p1")}</p>
      <p>{t("Our Mission p2")}</p>
      <h3>
        {t("How it works?")} {t("The 3 C’s")}
      </h3>
      <div className={"row"}>
        <div className={"col-sm-6 col-md-4"}>
          <div className={"thumbnail"}>
            <i className={"fa fa-3x fa-phone"} />
            <div className={"caption"}>
              <h3>{t("Call")}</h3>
              <p>{t("All it takes is one phone call...")}</p>
            </div>
          </div>
        </div>
        <div className={"col-sm-6 col-md-4"}>
          <div className={"thumbnail"}>
            <i className={"fa fa-3x fa-object-ungroup"} />
            <div className={"caption"}>
              <h3>{t("Compare")}</h3>
              <p>{t("We present several package options...")}</p>
            </div>
          </div>
        </div>
        <div className={"col-sm-6 col-md-4"}>
          <div className={"thumbnail"}>
            <i className={"fa fa-3x fa-pencil"} />
            <div className={"caption"}>
              <h3>{t("Complete")}</h3>
              <p>{t("Once you decide...")}</p>
            </div>
          </div>
        </div>
      </div>
      <div id="customerQuote" className={"alert alert-error"}>
        <p>{t("I couldnt have asked ...")}</p>
        <p>Majda Rensberger</p>
        <p>Carpentersville, IL</p>
      </div>
      <br />
      <h3>{t("Our partners")}</h3>
      <div className={"promo header-stick carriers"}>
        <div className={"container clearfix"}>
          <img
            src={"/img/partners/aetna.png"}
            alt={"Aetna"}
            className={"partner-logo"}
          />
          <img
            src={"/img/partners/bcbs.png"}
            className={"partner-logo"}
            alt={"BCBS"}
          />
          <img
            src={"/img/partners/cigna.png"}
            className={"partner-logo"}
            alt={"Cigna"}
          />
          <img
            src={"/img/partners/Humana.png"}
            className={"partner-logo"}
            alt={"Humana"}
          />
          <img
            src={"/img/partners/transamerica.png"}
            className={"partner-logo"}
            alt={"Transamerica"}
          />
          <img
            src={"/img/partners/national-life-group.png"}
            className={"partner-logo"}
            alt={"National Life Group"}
          />
        </div>
        <hr />
      </div>
    </section>
  );
};

export default About;
