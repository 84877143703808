import React, { useState } from "react";
import PartnerThumbnail from "./PartnerThumbnail.js";
import { initialState } from "../reducers/healthInsurancePartners";
import { useTranslation } from "react-i18next";

const Rows = ({ rows }) => {
  return rows.map((row) => (
    <div className={"row"} key={row.id}>
      {row.items.map((partner) => (
        <PartnerThumbnail partner={partner} key={partner.id} />
      ))}
    </div>
  ));
};

const HealthInsurancePartners = () => {
  const { t } = useTranslation();

  const [partners] = useState(initialState);

  return (
    <section id="health-insurance" className="home-section bg-gray text-center">
      <h3>
        {t("Health Insurance")} - {t("Partners")}
      </h3>
      <h5>{t("Individual Major Medical")}</h5>
      <Rows rows={partners} />

      <h5>{t("Limited Medical Plans")}</h5>
      <ul>
        <li>
          <a
            href="http://www.manhattanlife.com/agency/DajanaDikic52TH167"
            target="_quote"
          >
            Manhattan Life
          </a>
        </li>
      </ul>
      <h5>{t("Group Individual Major Medical")}</h5>
    </section>
  );
};

export default HealthInsurancePartners;
