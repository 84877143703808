import React from "react";
import { useTranslation } from "react-i18next";

const Intro = () => {
  const { t } = useTranslation();

  return (
    <div id="intro" className={"intro"}>
      <div className={"slogan"}>
        <h2>
          {t("WELCOME TO")} &nbsp;
          <span className="text_color">Spica {t("Insurance")}</span>
        </h2>
      </div>
      <div className={"page-scroll"}>
        <a href={"#about"} className={"btn btn-circle"}>
          <i className={"fa fa-angle-double-down animated"} />
        </a>
      </div>
      <div id="obamaInfo" className={"jumbotron"}>
        <p className={"title"}>{t("Don't miss Open Enrollment")}</p>
        <p className={"subtitle"}>
          {t("November 1st, 2024 until Jan 15th, 2025")}
        </p>
        <p>
          <button className="btn btn-info">{t("Click Here")}</button>&nbsp;{" "}
          {t("or")} {t("call")} &nbsp;
          <a href="tel:847-873-5385" className={"btn btn-info"}>
            847-873-5385
          </a>
        </p>
      </div>
    </div>
  );
};

export default Intro;
