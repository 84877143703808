import React from "react";
import { useTranslation } from "react-i18next";
const PartnerThumbnail = ({ partner }) => {
  const { t } = useTranslation();

  return (
    <div className={"col-sm-6 col-md-4"}>
      <div className={"thumbnail"}>
        {partner.logoImage && (
          <img
            src={partner.logoImage}
            className={"partner-logo"}
            alt={"logo"}
          />
        )}
        {partner.quoteLink && partner.quoteLink.startsWith("tel:") && (
          <div className={"caption"}>
            {partner.name && <h3>{partner.name}</h3>}
            <div>{t("For Quote")} &nbsp;</div>
            <a href={partner.quoteLink} className={"btn btn-info"}>
              {t("Call Us")}
            </a>
            <span>&nbsp; at 847-873-5385</span>
          </div>
        )}
        {!partner.quoteLink.startsWith("tel:") && (
          <div className={"caption"}>
            {partner.name && <h3>{partner.name}</h3>}
            <a
              href={partner.quoteLink}
              target="_quote"
              className={"btn btn-info"}
            >
              {t("Get a Quote")}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerThumbnail;
