import React from "react";
import withStyles from "react-jss";
import { useTranslation } from "react-i18next";

const styles = {
  list: {
    listStyle: "none",
  },
};

const LifeInsurancePartners = ({ classes }) => {
  const { t } = useTranslation();

  return (
    <section id="life-insurance" className="home-section text-center">
      <h3>
        {t("Life Insurance")} {t("Partners")}
      </h3>
      <h5>{t("Term Life")}</h5>
      <ul className={classes.list}>
        <li>Life of SouthWest</li>
        <li>Manhattan Life</li>
        <li>Standard Security Life</li>
        <li>TransAmerica</li>
      </ul>
      <h5>{t("Whole Life")}</h5>
      <ul className={classes.list}>
        <li>Americo</li>
        <li>Life of SouthWest</li>
        <li>Manhattan Life</li>
        <li>Standard Security Life</li>
        <li>TransAmerica</li>
      </ul>
      <h5>{t("Universal Life")}</h5>
      <ul className={classes.list}>
        <li>Life of SouthWest</li>
      </ul>
    </section>
  );
};

export default withStyles(styles)(LifeInsurancePartners);
